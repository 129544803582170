@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
}

.location-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px;
  margin: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.location-card:hover {
  transform: scale(1.05);
}

.location-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 15px;
}

.location-card h3 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.location-card p {
  font-size: 16px;
  color: #7f8c8d;
}

.location-card a {
  text-decoration: none;
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  transition: background-color 0.3s ease;
}
.sss {
  font-size: 60px;
}
.map-button {
  background-color: #3498db;
}

.map-button:hover {
  background-color: #2980b9;
}

.whatsapp-button {
  background-color: #25d366;
  margin-top: 10px;
}

.whatsapp-button:hover {
  background-color: #1ebe57;
}

.map-button .fa-map-marker-alt,
.whatsapp-button .fa-whatsapp {
  margin-right: 8px;
}
